footer{
  margin-top: 15px;
  background-color: rgb(70,70,70);
  border-radius: 20px;
  .col-sm-4{
    padding-left: 30px;
    .btn{
      margin: 5px 10px 5px 0;
      display: block;
    }
  }
  h5{
    color:#FFF;
    font-size:16px;
  }
  #terms-and-conditions{
    ul{
      list-style-type: none;
      padding-left: 0;
      a{
        color: #b5b5b5;
      }
    }
  }
  #play-store-logo{
    display: inline;
  }
  #app-store-logo{
    display: inline;
  }
}

#ata-seal{
  padding: 10px 0 10px;
}