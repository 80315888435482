div#partners-main{
  h1{
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 30px;
  }
  .blue-tag {
    font-size: 20px;
    color: $ucm-blue;
    font-weight: 700;
  }

  .gray-border-right {
    border-right: 5px solid #eeeeee
  }

  #become-member-btn1 {
    background-color: $ucm-green;
    margin-top: 25px;
    color: white;
    font-size: 25px;
    font-weight: 900;
    width: 350px;
  }

  .signing-up {
    margin: 20px 0 0 0;
    font-size: 1.5em;
    font-weight: 800;
  }

  #become-member-btn {
    background-color: $ucm-orange;
    margin-top: 25px;
    color: white;
    font-size: 25px;
    font-weight: 900;
    width: 350px;
  }

  .m {
    margin-bottom: 55px;
  }

  .ml {
    margin-bottom: 25px;
  }

  .gray-border-left {
    border-left: 5px solid #eeeeee
  }

  blockquote {
    //position: relative;
    //padding: 10px 10px 10px 50px;
    //background-image: none;
    //border-left: none;
    margin-top: 25px;
    padding: 0;
    display: block;
    border-left: none;
    p, cite {
      font-family: "Cardo", serif;
      color: black;
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;
    }

    p {
      font-style: oblique;
    }
  }

  cite {
    font-style: normal;
    position: relative;
    left: 70%;
  }

  .left-25 {
    margin-left: 25px;
  }
}