.united-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.united-modal-content {
  border-radius: 0;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  border: 4px solid $ucm-blue;
  p {
    color: $ucm-blue;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  #logo {
    max-width: 60%;
  }
  #old-logo {
    max-width: 40%;
    margin-bottom: 15px;
  }
}

.modal-content{
  border-radius: 0;
}

#new-brand-click-here {
  background-color: $ucm-blue;
  max-width: 300px;
  &:hover, &:active {
    background-color: lighten($ucm-blue, 20%);
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}