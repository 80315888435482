article#faqs{
  h1{
    font-weight: 600;
    color: #666666;
    font-size: 22px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial;
    line-height: 22px;
    margin-top: 0;
  }
  div#faqs-quote{
    blockquote {
      color: #999999;
      background: #ffffff url($image-dir + '/blockquote.png') no-repeat 10px 6px;
      padding: 10px 10px 10px 50px;
      margin-bottom: 50px;
      font-size: 15px;
      font-style: italic;
      line-height: 24px;
      font-family: Georgia, "Times New Roman", Times, serif;
    }
  }
  div#faqs-content{
    .faq{
      border-top: 1px solid #e8e8e8;
      div.answer{
        display: none;
        padding: 0 20px 20px 50px;
        ol{
          li{
            margin: 20px 0;
          }
        }
      }
      p.title{
        color: #666666;
        font-size: 13px;
        line-height: 21px;
        padding: 20px 25px 20px 50px;
        background: white url($image-dir + '/plus.png') no-repeat 20px 23px;
        cursor: pointer;
        margin-bottom: 0;
        &:hover{
          background-color: #f8f8f8;
        }
        &.expanded{
          background: white url($image-dir + '/minus.png') no-repeat 20px 23px;
          color: $ucm-blue;
          font-weight: 600;
        }
      }
    }
  }
}