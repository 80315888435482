article#tc3-page{
  .green-background{
    width: 100%;
    background-color: #1B5630;
    margin-bottom: 5px;
  }

  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  h1{
    color: #1B5630;
    font-size: 30px;
    margin-top: 25px;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  }
  h2{
    color: #1B5630;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  }
  h3{
    color: #1B5630;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    font-size: 2.0em;
    transform: scaleY(1.5);
    font-style: italic;
    padding: 25px;
  }
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      padding-left: 1em;
      text-indent: -.7em;
      font-size: 1.3em;
      &:before{
        content: "■";
        color: #1B5630;
        font-size: 1.5em;
      }
    }
  }
  .tc3-bold{
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    font-weight: bold;
    font-size: 1.7em;
    color: black;
    line-height: 1.8em;
  }
  #become-member-button{
    background-color: #1B5630;
    color: white;
    display: block;
    border: 5px solid wheat;
    height: 100px;
    padding-top: 25px;
    font-size: 1.8em;
    &:hover{
      background-color: lighten(#1B5630,20%);
      -webkit-animation: neon3 1.5s ease-in-out infinite alternate;
      -moz-animation: neon3 1.5s ease-in-out infinite alternate;
      animation: neon3 1.5s ease-in-out infinite alternate;
    }
  }

  @-webkit-keyframes neon3 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FFDD1B, 0 0 35px #FFDD1B, 0 0 40px #FFDD1B, 0 0 50px #FFDD1B, 0 0 75px #FFDD1B;
    }
  }

  blockquote{
    position: relative;
    padding: 10px 10px 10px 50px;
    background-image: none;
    border-left: none;
    p, cite{
      font-family: "Cardo", serif;
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;
      font-style: oblique;
    }
    &::before{
      position: absolute;
      display: block;
      width: 119px;
      height: 85px;
      background: url($image-dir + "/tlquote.png");
      background-position: left top;
      background-repeat: no-repeat;
      top: -5px;
      left: 10px;
      content: '';
    }
    &::after{
      position: absolute;
      display: block;
      width: 119px;
      height: 85px;
      background: url($image-dir + "/brquote.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      bottom: -30px;
      right: 20px;
      content: '';
    }
  }
}