article#employee_services_agreement {
  ol { counter-reset: item }
  li { display: block }
  li:before { content: counters(item, ".") " "; counter-increment: item }
  .underline{
    text-decoration: underline;
  }
  .agreement{
    font-weight: 900;
    font-size: 18px;
    color: black;
  }
}