.college-banner {
  min-height: 335px;
}
div#colleges-main{
  .grey-bar-center {
    width: 200px;
    border-top-width: 2px;
  }
  h1{
    color: $ucm-orange;
    font-family: "Raleway", sans-serif;
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;
  }
  p, strong {
    font-size: 12pt;
    //font-weight: normal;
  }
  strong {
    color: black;
    font-weight: 500;
  }
  h2, .h2{
    color: $ucm-blue;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  ul {
    li {
      font-size: 1.3em;
      list-style-image: url($image-dir + "/list-check.png");
    }
  }
  blockquote{
    //position: relative;
    //padding: 10px 10px 10px 50px;
    //background-image: none;
    //border-left: none;
    padding: 0;
    border-left: none;
    cite {
      position: relative;
      left: 80%;
    }
    p, cite{
      font-family: "Cardo", serif;
      color: black;
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;
    }

    p {
      font-style: oblique;
    }

    cite {
      font-style: normal;
    }
    //&::before{
    //  position: absolute;
    //  display: block;
    //  width: 119px;
    //  height: 85px;
    //  background: url($image-dir + "/tlquote.png");
    //  background-position: left top;
    //  background-repeat: no-repeat;
    //  top: -5px;
    //  left: 10px;
    //  content: '';
    //}
    //&::after{
    //  position: absolute;
    //  display: block;
    //  width: 119px;
    //  height: 85px;
    //  background: url($image-dir + "/brquote.png");
    //  background-position: right bottom;
    //  background-repeat: no-repeat;
    //  bottom: -30px;
    //  right: 20px;
    //  content: '';
    //}
  }
}

div#colleges-sidebar{
  ul{
    padding-left: 15px;
    li{
      margin-bottom: 10px;
    }
  }
  a#become-member-btn{
    background-color: $ucm-orange;
    display: block;
    margin: 10px auto;
    color: white;
    &:hover{
      background-color: lighten($ucm-orange,20%);
    }
  }
  p#signing-up-is-easy{
    color: $ucm-blue;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: 800;
    display: block;
    text-align: center;
  }
  label#find-college-label{
    color: $ucm-blue;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    display: block;
    text-align: center;
  }
  .go{
    background-color: $ucm-orange;
    display: block;
    margin: 15px 0;
    color: white;
    font-size: 20px;
    &:hover{
      background-color: lighten($ucm-orange,20%);
    }
  }
}