section#about-top{
  div#about-content{
    h1{
      font-weight: 600;
      color: #666666;
      font-size: 22px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial;
      line-height: 22px;
      margin-top: 0;
    }
  }
  div#about-quote{
    blockquote{
      background: #ffffff url($image-dir + '/blockquote.png') no-repeat 10px 6px;
      padding: 10px 10px 10px 50px;
      p, cite {
        color: #f46922;
        font-style: italic;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        font-family: Georgia, "Times New Roman", Times, serif;
      }
    }
  }
}
section#about-profiles{
  .profile{
    img{
      margin: 0 20px 20px 0;
    }
    h2{

    }
    h3.profile-title{
      margin: 0;
    }
    p.profile-license{
      margin-bottom: 20px;
    }
  }
}