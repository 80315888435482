article#mental-health {
  h2#telepsychiatry, h2#counseling{
    color: #d8a008;
    font-family: "Raleway", sans-serif;
    font-size: 23px;
    line-height: 30px;
    font-weight: 400;
  }

  button.blue-btn{
    background-color: $ucm-blue;
    color: white;
    &:hover{
      background-color: lighten($ucm-blue,20%);
    }
  }

  p {
    font-weight: 600;
    font-size: 17px;
  }

  ul {
    li {
      font-weight: 500;
      font-size: 19px;
    }
  }

  ul#check-list {
    list-style-image: url($image-dir + "/list-check.png");
  }
}