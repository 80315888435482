div#benefits-main{
  h1{
    font-size: 22px;
    color: #999999;
    font-weight: 600;
  }
  .orange{
    color: #f46922;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
  p strong{
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: block;
    margin-bottom: 10px;
  }
  ul#benefits-list{
    list-style-image: url($image-dir + "/list-check.png");
    margin-top: 25px;
  }
}
div#benefits-sidebar{
  a{
    background-color: $ucm-blue;
    margin: 15px auto;
    color: white;
    display: block;
    max-width: 300px;
    &:hover,&:active{
      background-color: lighten($ucm-blue,20%);
    }
  }
  blockquote{
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #999999;
    background: #ffffff url($image-dir + '/blockquote.png') no-repeat 10px 6px;
    font-size: 15px;
    font-style: italic;
    padding: 10px 10px 10px 50px;
    p{
      line-height: 24px;
    }
  }
}