#businesses-main{
  $orange : #ff6600;
  $blue : #3366ff;
  .orange{
    color: $orange;
  }
  .blue{
    color: $blue;
  }
  .black{
    color: black;
  }
  .no-top-margin{
    margin-top: 0;
  }
  h1{
    font-weight: 600;
    color: #666666;
    font-size: 22px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial;
    text-align: center;
  }

  h2{
    font-weight: 600;
    font-size: 22px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial;
    text-align: center;
  }
  h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #666666
  }
  h4{
    color: black;
    font-size: 24px;
    line-height: 34px;
    font-weight: 300;
  }
  h5#you-cannot-afford{
    color: #00ccff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
  }
  h3#your-employees-save{
    color: black;
    font-weight: 600;
    padding: 0;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }
  blockquote{
    padding: 10px 10px 10px 50px;
    background: #ffffff url($image-dir + '/blockquote.png') no-repeat 10px 6px;
    p{
    color: #999999;
    font-style: italic;
    font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 16px;
    }
    cite{
      color: #999999;
      font-style: italic;
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 18px;
    }
    .bq-read-more{
      color: $ucm-blue;
      font-style: italic;
      font-family: Georgia, "Times New Roman", Times, serif;
      &:hover{
        cursor: pointer;
      }
    }
    .bq-full{
      display: none;
    }
  }
  article#benefits{
    h5{
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      color: #666666
    }
  }
  article#health-and-wellness{
    h4{
      color: #3366ff;
      font-size: 20px;
      line-height: 30px;
      font-weight: 100;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial;
      text-align: center;
    }
    ul{
      list-style-type: none;
      li{
        font-weight: 600;
        text-align: center;
        padding: 5px 0;
        color: #666666;
        a{
          color: black;
        }
      }
    }
  }

  #contact-us-btn{
    background-color: $ucm-blue;
    color: white;
    &:active, &:hover{
      background-color: lighten($ucm-blue,20%);
    }
    display: block;
    margin: 25px auto;
    max-width: 200px;
  }
}
div#businesses-sidebar{

  img{
    margin-bottom: 20px;
  }
}
