h1#home-slogan{
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #666666;
}

a#learn-how-to-become-a-member{
  background-color: $ucm-blue;
  color: white;
  width: 300px;
  &:hover{
    background-color: lighten($ucm-blue,20%);
  }
}

h3#join-us-on{
  color: #434242;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}