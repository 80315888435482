div#patient-portal, #header-patient-portal-form{
  color: #666666;
  h1{
    font-size: 22px;
  }
  button#login-btn, button#header-login-btn{
    background-color: $ucm-blue;
    color: white;
    &:hover{
      background-color: lighten($ucm-blue,20%);
    }
  }
}