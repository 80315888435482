div#call-us-now{
  background: $ucm-blue;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  z-index: 500;
  color: white;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  line-height: 21px;
  background-clip: border-box;
  background-origin: padding-box;
  .openbtn{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 14px;
    display: block;
    text-indent: -9999px;
    background: $ucm-blue url($image-dir + 'topbar.png') no-repeat bottom center;
    background-size: 100%;
    cursor: pointer;
    width: 100%;
  }
  #close-call-us-now{
    position: absolute;
    right: 5px;
    bottom: 8px;
  }
  .barrier{
    margin-bottom: 10px;
  }
}