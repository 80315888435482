.blue-background{
  width: 100%;
  background-color: $ucm-blue;
  margin-bottom: 5px;
}
//#telemedicine-for-healthcare-main {
//  ul{
//    list-style-image: url($image-dir + "/list-check.png");
//  }
//}

h2#work-less-earn-more{
  font-style: italic;
  color: black;
  font-size: 18px;
  line-height: 22px;
}

h1#we-offer-branded {
  color: #d8a008;
  font-family: "Raleway", sans-serif;
  font-size: 23px;
  line-height: 30px;
  font-weight: 400;
}

ul#build-your-program {
    list-style-image: url($image-dir + "/list-check.png");
    li{
      font-weight: 500;
      font-size: 19px;
    }
}

#you-control {
  font-size: 1.7em;
  line-height: 1.5em;
  font-weight: 800;
}


#health-care-main {
  p {
    font-size: 1.2em;
    line-height: 1.2em;
  }
}

div#join-ucm-box{
  h4{
    font-size: 24px;
    line-height: 24px;
    color: $ucm-blue;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
  }
  ul{
    padding-bottom: 20px;
    li{
      font-weight: bold;
      padding-top: 10px;
    }
  }
}

.health-title{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: $ucm-blue
}

.orange-button {
  background-color: $ucm-orange;
  color: white;
  min-width: 300px;
  font-size: 2em;
  &:hover, &:focus, &:active {
    background-color: lighten($ucm-orange, 20%);
    color: white;
  }
  margin-bottom: 15px;
}

ul.health-list{
  li strong{
    font-weight: 700;
  }
}