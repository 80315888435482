header{
  h2.slogan{
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    font-family: Berkeley Bold Italics;
    color: black;
    font-style: italic;
    margin-left: 30px;
    text-align: center;

  }

  button#request-a-consult-btn{
    background-color: $ucm-blue;
    color: white;
    margin-top: 15px;
    &:hover{
      background-color: lighten($ucm-blue,20%);
    }
  }
}

nav.navbar{
  margin-top: 10px;
  @media (max-width: 768px) {
    background-color: $ucm-blue;
    border: 2px solid white;
    .navbar-toggle{
      .icon-bar{
        background-color: white;
      }
      &:hover, &:focus{
        background-color: lighten($ucm-blue,20%);
      }
    }
    ul.navbar-nav{
      li a{
        text-transform: uppercase;
        color: white;

      }
    }
  }
  @media (min-width: 768px) {
    background-color: transparent;
    border: none;
    ul.navbar-nav {

      margin: 0 auto;
      display: table;
      table-layout: fixed;
      float: none;

      li {
        background: url($image-dir + '/nav-divider.png') no-repeat center right;
        a {
          text-transform: uppercase;
          color: #666666;
          padding-top: 7px;
          font-size: 12px;
          font-weight: 700;
          padding-bottom: 7px;
          border-top: 1px solid #d5d5d5;
          border-bottom: 1px solid #d5d5d5;
          &:hover,&.active {
            color: $ucm-blue;
            border-color: $ucm-blue;
          }
        }
      }
    }
  }
}
p#orange-phone-number, p#orange-phone-number-backup{
  color:   #f46922;
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
p#small-phone-number{
  font-weight: 600;
  font-size: 16px;
  a{
    color: #3366ff
  }
}

a.header-icon-link {
  color: white;
  &:hover,&:active {
    color: lightgrey;
  }
}

#vip-doc{
  @media(min-width:767px){
    //margin-left: -120px;
  }
  @media(max-width:767px){
    display: block;
  }
}
//#icon-area{
//  @media(max-width:767px){
//    display: block;
//    width: 17%;
//    margin: auto;
//    //padding-left: 45px;
//  }
//}

.header-app-download {
  color: white;
  font-weight: 900;
}

#close-x {
  //color: white;
  font-size : 30px;
}