$ucm-blue: #00a4df;
$ucm-orange: #FC9101;
$ucm-gray: #808185;
$ucm-green: #1be153;
$image-dir: '../images/';
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "callusnow";
@import "header";
@import "footer";
@import "home";
@import "contact";
@import "telemedicine-for-healthsystems-practices-providers";
@import "telemedicine-for-businesses";
@import "telemedicine-for-colleges";
@import "how_it_works";
@import "telemedicine-benefits";
@import "about";
@import "faqs";
@import "patient_portal";
@import "fire";
@import "tc3";
@import "medaire";
@import "employee_service_agreement";
@import "modal";
@import "mental-health";
@import "group-home";
@import "workers_comp";
body{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial
}
p{
  font-weight: 500;
  color: #6d6e71;
  font-size: 14px;
  line-height: 17px;
  font-family: "Raleway", sans-serif;
}
.signature-line{
  background-color: black;
  height: 1px;
  width: 250px;
  margin: 45px 0 10px 0;
  display: inline-block;
}

.intrinsic-container {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 40%;
}

.intrinsic-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ucm-logo {
  margin-left: -15px;
}

//Blog

#blog-post {
  p {
    font-size: 1.2em;
    line-height: 1.2em;
  }
}