article#group-home {
  h2#what-we-do, h2#why-you-win{
    color: #d8a008;
    font-family: "Raleway", sans-serif;
    font-size: 23px;
    line-height: 30px;
    font-weight: 400;
  }

  button.blue-btn{
    background-color: $ucm-blue;
    color: white;
    &:hover{
      background-color: lighten($ucm-blue,20%);
    }
  }

  p {
    font-weight: 700;
    font-size: 21px;
    line-height: 22px;
    margin: 15px 0;
    color: black;
  }

  ul {
    li {
      font-weight: 500;
      font-size: 19px;
    }
  }

  .grey-bar-center {
    width: 200px;
    border-top-width: 2px;
  }

  blockquote{
    padding: 0;
    border-left: none;
    cite {
      position: relative;
      left: 80%;
    }
    p, cite{
      font-family: "Cardo", serif;
      color: black;
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;
    }

    p {
      font-style: oblique;
    }

    cite {
      font-style: normal;
    }
  }
}