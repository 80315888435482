#fire-icon{
  padding-top: 10px;
}

article#fire{
  color: darken($ucm-blue,5%);
  #become-member-btn{
    background-color: $ucm-blue;
    color: white;
    &:active, &:hover{
      background-color: lighten($ucm-blue,20%);
    }
  }
  p{
    color: darken($ucm-blue,5%);
    font-weight: 600;
    margin-top: 10px;
  }
  h2.h1{
    margin-top: 50px;
    font-weight: 900;
    color: red;
    -webkit-text-stroke: 1px black;
  }
  div#always-rushing-in p{
    font-size: 24px;
    margin-top: 50px;
    line-height: 30px;
  }
  ul li {
    color: red;
    font-size: 22px;
    font-weight: 800;
    -webkit-text-stroke: 0.5px black;
  }
  h1#a-few-become-firemen{
    font-size: 20px;
    color: red;
    font-style: italic;
    text-align: center;
    -webkit-text-stroke: 0.3px black;
  }
  .fire{
    margin-top: 50px;
    font-weight: 900;
    color: red;
    text-shadow: 0 0 5px #fefcc9, 3px -3px 9px #feec85, -6px -6px 13px #ffae34, 7px -14px 16px #ec760c, -6px -15px 15px #cd4606, 0 -20px 17px #973716, 3px -20px 15px #451b0e;
    font-size: 30px;
    line-height: 50px;
  }
  #use-membership-code{
    font-weight: 900;
  }
}